<template>
  <div class="content">
    <template v-for="(item, i) in data">
      <!-- 移动端 -->
      <div class="contentWeb" :key="i" @click="detailsFn(item.id)">
        <i>{{ item.publishTime }}</i>
        <div class="imgText">
          <img :src="item.coverImg" alt="" />
          <h3>{{ item.title }}</h3>
        </div>
      </div>
      <!-- PC端 -->
      <div :key="i + 10000" class="contentI">
        <img :src="item.coverImg" alt="" class="img1" />
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <span>{{ item.publishTime }}</span>
          <p>{{ item.subTitle }}</p>
          <div class="img2" @click="detailsFn(item.id)"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
// import store from "@/store";
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    detailsFn(key) {
      // store.state.details = key;
      sessionStorage.setItem("details", key);
      this.$router.push({
        path: "/details",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .content {
    .contentI {
      display: none;
    }
    .contentWeb {
      overflow: hidden;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;

      i {
        font-size: 12px;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #606060;
        line-height: 11px;
        margin: 12px 0;
        display: block;
        width: 100%;
        text-align: center;
      }
      .imgText {
        width: 17.15rem;
        height: 12rem;
        margin-bottom: 0.61rem;
        border-radius: 0.26rem;
        box-shadow: 0px 0px 0.26rem 0px rgba(0, 0, 0, 0.302);
        img {
          width: 17.15rem;
          height: 8.96rem;
          border-radius: 0.26rem 0.26rem 0 0;
        }
        h3 {
          margin: 0.72rem 0 0.72rem 0.56rem;
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #000000;
          line-height: 0.82rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .content {
    // 偶数样式
    .contentI:nth-child(even) {
      background: #f4f4f4;
    }
    // 偶数样式
    .contentWeb {
      display: none;
    }
    .contentI {
      height: 7.22rem;
      width: 19.2rem;
      //position: relative;
      .img1 {
        width: 8.33rem;
        // height: 4.93rem;
        float: left;
        margin: 1rem 0.45rem 0 1.51rem;
      }
      .contentText {
        width: 7.4rem;
        // height: 4.97rem;
        float: left;
        margin-top: 1rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
          margin-top: 0.25rem;
        }
        h3 {
          // height: 0.7rem;
          font-size: 0.36rem;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #2f2f2f;
          line-height: 0.59rem;
        }
        span {
          height: 0.28rem;
          font-size: 0.2rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #606060;
          line-height: 0.23rem;
        }
        p {
          text-indent: 0.4rem;
          margin-top: 0.13rem;
          // height: 3rem;
          font-size: 0.2rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 0.43rem;
        }
        .img2 {
          // margin-top: 0.38rem;
          cursor: pointer;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.5rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: 100% 100%;
        }
        .img2:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
